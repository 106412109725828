import React, { useState } from "react"
import Section from "../../../components/section/Section"
import imgWidget from "../../../assets/images/decorations/chatKit/section2/img-widgetScreen.svg"
import imgTest from "../../../assets/images/decorations/chatKit/section2/imgTest.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  imgWidget: imgWidget,
  imgTest: imgTest,
}

const AttrsSelect = ({ items, setValue, currentAttrValue, data }: { items: any; setValue: any; currentAttrValue: string; data: any }) => {
  const [showList, showListStatus] = useState(false)
  const { button } = data.data.content.ChatKitSectionSecondConfig

  return (
    <div className="sova-section-container sova-section-container_chatKit-2-attrsSelect">
      <button className="sova-section-child sova-btn sova-btn_primary sova-btn_small sova-section-child_chatKit-2-selectBtn" onClick={() => showListStatus(!showList)}>
        {button}
      </button>
      {showList && (
        <div className="sova-section-container sova-section-container_chatKit-2-attrsListContainer">
          <ul className="sova-section-child-container sova-section-child-container_chatKit-2-attrsList">
            {items.map(({ value, img }: { value: string; img: string }, key: number) => (
              <li
                key={key}
                className={`sova-listItem sova-listItem_chatKit-2-mobAttrsListItem sova-listItem_chatKit-2-mobAttrsListItem_active-${value === currentAttrValue ? true : false}`}
                onClick={() => {
                  setValue({ img: img, value: value })
                  showListStatus(false)
                }}
              >
                {value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

const ChatKitSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { attr, title, text } = data.data.content.ChatKitSectionSecondConfig

  const [attrvalue, setValue] = useState(attr[0])

  return (
    <Section className={`${className}`}>
      <div className="sova-section-container">
        <h2 className="sova-h2">{title}</h2>
        <p className="sova-section-child sova-section-paragraph sova-h4 sova-h4_chatKit-2-text">{text}</p>
        <div className="sova-section-container sova-section-container_row sova-section-container_chatKit-2-widgetContainer">
          <AttrsSelect data={data} items={attr} setValue={(item: { img: string; value: string }) => setValue(item)} currentAttrValue={attrvalue.value} />
          <img src={images[attrvalue.img]} className="sova-img sova-img_chatKit-2-widget" />
          <div className="sova-section-child-container sova-section-child-container_chatKit-2-widgetAttrs">
            <ul className="sova-section-child-container sova-section-child-container_chatKit-2-widgetAttrsList">
              {attr.map(({ value, img }: { value: string; img: string }, key: number) => (
                <li
                  key={key}
                  onClick={() => setValue({ img: img, value: value })}
                  className={`sova-section-child sova-p-tiny sova-section-child_chatKit-2-attrItem sova-section-child_chatKit-2-attrItem_active-${value === attrvalue.value ? true : false}`}
                >
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Section>
  )
}

ChatKitSectionSecond.displayName = "ChatKitSectionSecond"

export default ChatKitSectionSecond
