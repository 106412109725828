import React from "react"
import Section from "../../../components/section/Section"
import card1 from "../../../assets/images/decorations/chatKit/section3/card-img1-for-chatKit-page-sova.ai.svg"
import card2 from "../../../assets/images/decorations/chatKit/section3/card-img2-for-chatKit-page-sova.ai.svg"
import card3 from "../../../assets/images/decorations/chatKit/section3/card-img3-for-chatKit-page-sova.ai.svg"
import integrationImg from "../../../assets/images/decorations/chatKit/section3/img-integration-for-chatKit-page-sova.ai.svg"
import moduleStrImg from "../../../assets/images/decorations/chatKit/section3/img-moduleStr-for-chatKit-page-sova.ai.svg"

interface Imgs {
  [k: string]: string
}
const images: Imgs = {
  card1: card1,
  card2: card2,
  card3: card3,
  integrationImg: integrationImg,
  moduleStrImg: moduleStrImg,
}

const ChatKitSectionThird = ({ className, data }: { className: string; data: any }) => {
  const { cards, blocks, title } = data.data.content.ChatKitSectionThirdConfig
  return (
    <Section className={className}>
      <h2 className="sova-h2">{title}</h2>
      <div className="sova-section-container sova-section-container_row sova-section-container_chatKit-3-advantagesList">
        {cards.map(({ imgSrc, imgAlt, title, text }: { imgSrc: string; imgAlt: string; title: string; text: string }, key: number) => (
          <div className="sova-section-column sova-section-card_chatKit-3" key={key}>
            <img src={images[imgSrc]} alt={imgAlt} className="sova-section-child sova-section-child_chatKit-3-cardImg" />
            <p className="sova-section-child sova-section-child_chatKit-3-card sova-p-medium">{title}</p>
            <p className="sova-section-child sova-section-child_chatKit-3-card sova-p-normal">{text}</p>
          </div>
        ))}
      </div>
      <div className="sova-section-container">
        {blocks.map(({ imgSrc, imgAlt, title, text }: { imgSrc: string; imgAlt: string; title: string; text: string }, key: number) => (
          <div className={`sova-section-container sova-section-container_row sova-section-container_chatKit-3-block sova-section-container_chatKit-3-block-${key}`} key={key}>
            <img src={images[imgSrc]} alt={imgAlt} className="sova-section-container_chatKit-3-blockImg" />
            <div className={`sova-section-child-container sova-section-child-container_chatKit-3-blockText sova-section-child-container_chatKit-3-blockText-${key}`}>
              <h3 className={`sova-section-child sova-section-child_chatKit-3-block sova-section-child_chatKit-3-blockTitle sova-section-child_chatKit-3-blockTitle-${key}`}>{title}</h3>
              <p className="sova-section-child sova-section-child_chatKit-3-block sova-section-child_chatKit-3-blockText sova-p-normal">
                <img src={images[imgSrc]} alt={imgAlt} className={`sova-section-container_chatKit-3-blockImgMob sova-section-container_chatKit-3-blockImgMob-${key}`} />
                {text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

ChatKitSectionThird.displayName = "ChatKitSectionThird"

export default ChatKitSectionThird
