import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import Section from "../../../components/section/Section"
import imgMob from "../../../assets/images/decorations/chatKit/section1/img-for-chatKit-pageMob-sova.ai.svg"

interface Dialog {
  quest: string
  answer: string
}

const ChatKitSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { dialogText, title, button } = data.data.content.ChatKitSectionFirstConfig

  const [dialogValues, setValues] = useState(dialogText[0])
  const handleDialog = (e: any) => {
    e.persist()
    const neededDialog = dialogText.find((el: any) => {
      return el.quest === e.target.value
    }) as Dialog

    return setValues({ quest: e.target.value, answer: neededDialog.answer })
  }

  return (
    <Section className={`${className} sova-fullscreen-height`}>
      <h1 className="sova-h1 sova-h1_centered">{title}</h1>
      <img src={imgMob} className="sova-img sova-img_chatKit-1-imgMob" alt="Illustration for ChatKit page mob version- SOVA.AI" />
      <div className="sova-section-container sova-section-container_row sova-section-container_chatKit-1-dialogContainer">
        <div className="sova-section-child-container sova-section-child-container_chatKit-1-dialog">
          <CSSTransition key={dialogValues.quest !== "" && dialogValues.quest} timeout={1000}>
            <div className="sova-section-child_chatkit-1-dialogQuestContainer sova-section-child_chatkit-1-dialogQuestContainer_animated">
              <p className={`sova-section-child_chatkit-1-dialogQuest sova-section-child_chatkit-1-dialogQuest_animated`}>{dialogValues.quest}</p>
            </div>
          </CSSTransition>
          <CSSTransition key={dialogValues.answer} timeout={1000}>
            <div className="sova-section-child_chatkit-1-dialogAnswerContainer sova-section-child_chatkit-1-dialogAnswerContainer_animated">
              <p className="sova-section-child sova-section-child_chatkit-1-dialogAnswer sova-section-child_chatkit-1-dialogAnswer">{dialogValues.answer}</p>
            </div>
          </CSSTransition>

          <div className="sova-section-child-container sova-section-child-container_row sova-section-child-container_chatKit-1-dialogBtnsContainer">
            {dialogText.map(({ quest }: any, key: number) => (
              <button className="sova-btn sova-btn_chatKit-1-dialogBtn" value={quest} key={key} onClick={e => handleDialog(e)}>
                {quest}
              </button>
            ))}
          </div>
        </div>
        <div className="sova-section-child-container sova-section-child-container_chatKit-1-getStarted">
          {/* <Link to={`/mainDocPage`} className="sova-section-child sova-section-child_chatKit-1-link sova-h4 sova-h4_link">
            Documentation
          </Link> */}
          <a href="https://github.com/sovaai/chatKit-lib">
            <button className="sova-btn sova-btn_primary sova-btn_big">{button}</button>
          </a>
        </div>
      </div>
    </Section>
  )
}

ChatKitSectionFirst.displayName = "ChatKitSectionFirst"

export default ChatKitSectionFirst
