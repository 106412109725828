import React from "react"

import ChatKitSectionFirst from "./chatKitSections/chatKitSectionFirst"
import ChatKitSectionSecond from "./chatKitSections/chatKitSectionSecond"
import ChatKitSectionThird from "./chatKitSections/chatKitSectionThird"
// import ChatKitSectionFourth from "./chatKitSections/chatKitSectionFourth"

const sections = [
  { component: (key: number, className: string, data: any) => <ChatKitSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <ChatKitSectionSecond data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <ChatKitSectionThird data={data} className={className} key={key} /> },
  // { component: (key: number, className: string, data: any) => <ChatKitSectionFourth data={data} className={className} key={key} /> },
]

const ChatKitSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-chatKit sova-section-chatKit_${i}`, data))}</>
}

ChatKitSections.displayName = "ChatKitSections"

export default ChatKitSections
