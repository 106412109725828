import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import ChatKitSections from "../pagesStructures/chatKit/chatKitSections"

const ChatKit = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiChatKit.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="chatKit" pageName="chatKit">
      <SEO description="ChatKit" title={pageContent.content.title} />
      <ChatKitSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default ChatKit
export const query = graphql`
  query ChatKit($url: String) {
    strapiChatKit(url: { eq: $url }) {
      content {
        content {
          ChatKitSectionFirstConfig {
            dialogText {
              answer
              quest
            }
            title
            button
          }
          ChatKitSectionFourthConfig {
            title
          }
          ChatKitSectionSecondConfig {
            button
            text
            title
            attr {
              img
              value
            }
          }
          ChatKitSectionThirdConfig {
            blocks {
              imgAlt
              imgSrc
              text
              title
            }
            cards {
              imgAlt
              imgSrc
              text
              title
            }
            title
          }
        }
        language
      }
    }
  }
`
